/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { fetchFile, searchDelegates, searchUsers, uploadPrivateFile, searchInsureds, searchCaisses } from "@/modules/common/_api";
import bindDeliveriesData from "@/helpers/_functions/bindDeliveriesData";

import * as api from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  editContract(context, { id, ...contractData }) {
    if (contractData.deliveries) {
      contractData.deliveries = bindDeliveriesData(contractData.deliveries);
    }

    return api.editPatientContract(id, contractData);
  },
  editPatientInformations(context, { _id, ...patientData }) {
    return api.editPatientInformations(_id, patientData);
  },
  editInsuredInformations(context, data) {
    const { _id, ...insured } = data;
    return api.editInsured(_id, insured);
  },
  uploadFile(context, { file, fieldname }) {
    const formdata = new FormData();
    formdata.append(fieldname, file);
    return uploadPrivateFile(formdata);
  },
  fetchInsureds(context, name) {
    return new Promise((resolve, reject) => {
      searchInsureds({ query: { name } })
        .then(res => {
          resolve(res.data.body.results);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchFile(context, file) {
    return new Promise((resolve, reject) => {
      fetchFile(file)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchInsureds(context, input) {
    return new Promise((resolve, reject) => {
      searchInsureds({ query: { input }, skip: 0, limit: 40, source: "remote" })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchCaisses(context, input) {
    return searchCaisses({ query: { input }, skip: 0, limit: 10, source: "local" });
  },
  searchDelegates(context, input) {
    return searchDelegates({ query: { input }, skip: 0, limit: 10, source: "local" });
  },
  searchUsers(context, input) {
    return searchUsers({
      query: { input, active: true },
      skip: 0,
      limit: 10,
      source: "local"
    });
  },
  getRenewalDocumentById(context, id) {
    return api.getRenewalDocumentById(id);
  },
  addCustomProtocol(context, data) {
    return api.addCustomProtocol(data);
  },
  addProductsReminder(context, data) {
    return api.addProductsReminder(data);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
