module.exports = {
  communityEntityInterventions: "communityEntityInterventions",
  authBan: "authBan",
  fastpay: "fastpay",
  woocommerce: "woocommerce",
  offlinePdf: "offlinePdf",
  synchroPatient: "synchroPatient",
  synchroStocks: "synchroStocks",
  addProductByScan: "addProductByScan",
  addProductManually: "addProductManually",
  support: "support",
  ordoclic: "ordoclic",
  interventions: "interventions",
  patientSignature: "patientSignature",
  deliveryNote: "deliveryNote",
  planificationGlobal: "planification.global",
  planificationOnInterventionAchieved: "planification.onInterventionAchieved",
  planificationOnContractCreation: "planification.onContractCreation",
  planificationActionButtons: "planification.actionButtons",
  reminders: "reminders",
  mailToPatientForNewAlert: "mailToPatientForNewAlert",
  patientsOrientedMessages: "patientsOrientedMessages",
  stockHandler: "stockHandler",
  prescriberAccount: "prescriberAccount",
  maintenance: "maintenance",
  selectMessageTypeOnExtranet: "selectMessageTypeOnExtranet",
  messaging: "messaging",
  communityTour: "communityTour",
  faxes: "faxes",
  productsReminder: "productsReminder",
  customProtocol: "customProtocol",
  sleepStudies: "sleepStudies"
};
