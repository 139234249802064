import commonSettings from "@common/settings/lorair";
import administrationMenu from "./menus/administration.bastide";
import prescriberMenu from "./menus/prescriber.bastide";
import extranetMenu from "./menus/extranet.bastide";
import administrationFooter from "./footer/administration.bastide";
import prescriberFooter from "./footer/prescriber.bastide";
import extranetFooter from "./footer/extranet.bastide";
import administrationDashboard from "./dashboard/administration.bastide";
import supportedLocales from "./supported-locales/supported-locales.lorair";

const sentryDsn = "https://df71b8bc6b6c481f8db2c4ecd2f669c7@o1283494.ingest.sentry.io/1878304";

const titleApp = "Lorair Access";
const name = "Lorair";
const longName = "Lorair";
const extranetAddresseeName = "Agence Lorair";

const headOffice =
  "36 rue du 19 Novembre - 57070 Metz <br /> S.A.S.U. au capital de 10 000€ - R.C.S Metz 539 275 578 - SIRET n°539 275 578 00026 - N° TVA Intracommunautaire : FR 24 539 275 578";

export default {
  ...commonSettings,
  administrationMenu,
  prescriberMenu,
  extranetMenu,
  administrationFooter,
  prescriberFooter,
  extranetFooter,
  sentryDsn,
  titleApp,
  name,
  longName,
  headOffice,
  administrationDashboard,
  supportedLocales,
  extranetAddresseeName
};
