module.exports = {
  /* KEY-VALUE */
  installation: "installation",
  abpm: "abpm",
  equipmentPurchase: "equipmentPurchase",
  o2Assessment: "o2Assessment",
  provideSupplies: "provideSupplies",
  hsat: "hsat",
  renewal: "renewal",
  renewalIni: "renewalIni",
  obs: "obs",
  hsatOnTherapy: "hsatOnTherapy",
  newPatientConsult: "newPatientConsult",
  discontinue: "discontinue",
  redoHsat: "redoHsat",
  sav: "customerService",
  customerService: "customerService",
  titration: "titration",
  teleobs: "teleobs",
  teleobsAuto: "teleobsAuto",
  recovery: "recovery",
  partialRecovery: "partialRecovery",
  delivery: "delivery",
  onDemand: "onDemand",
  prescriptionChange: "prescriptionChange",
  walkIn: "walkIn",
  phoneCall: "phoneCall",
  oneMonthDelivery: "oneMonthDelivery",
  twoMonthDelivery: "twoMonthDelivery",
  threeMonthDelivery: "threeMonthDelivery",
  materioVigilance: "materioVigilance",
  training: "training",
  unpairing: "unpairing",
  prescriptionEdit: "prescriptionEdit",
  remainder: "remainder",
  other: "other",
  oximetry: "oximetry",
  oximetryOnly: "oximetryOnly",
  capnographie: "capnographie",

  /* GROUP OF INTERVENTIONS */
  ignoreTelesuiviConsentState: ["phoneCall"],
  deliveries: ["delivery", "suppliesDelivery"],
  withoutDm: ["suppliesDelivery"],
  autoCRGeneration: [
    "installation",
    "titration",
    "obs",
    "renewal",
    "renewalIni",
    "teleobs",
    "prescriptionChange",
    "onDemand",
    "oximetry",
    "oximetryOnly",
    "capnographie",
    "coaching",
    "prescriptionEdit",
    "teleobsAuto",
    "recovery",
    "partialRecovery",
    "phoneCall"
  ],
  CPOGeneration: [
    "titration",
    "obs",
    "renewal",
    "renewalIni",
    "teleobs",
    "customerService",
    "prescriptionChange",
    "onDemand",
    "oximetry",
    "oximetryOnly",
    "capnographie",
    "coaching",
    "prescriptionEdit",
    "teleobsAuto",
    "phoneCall",
    "materiovigilance",
    "materiovigilanceResmed"
  ],
  CPIGeneration: ["installation"],
  CPRGeneration: ["recovery", "partialRecovery"],
  pharmacistVisit: ["pharmacistVisit"],
  productsReminderPrefill: ["delivery", "oneMonthDelivery", "twoMonthDelivery", "threeMonthDelivery", "installation"]
};
